import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import NotFound from "./pages/NotFound";
import Live from "./pages/Live";
import PhoneForm from "./pages/PhoneForm";

function Home() {
  return (
    <div className="App">
      <div className="container">
        <div className="text">personas , perspectives , people , pages , </div>
        <div className="title">nothing is ever wrong</div>
        <div className="links">
          <a href="https://www.instagram.com/nothingiseverwrong/">IG</a>
          <a href="https://x.com/iseverwrong">X</a>
          <a href="https://www.youtube.com/@nothingiseverwrong">YT</a>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/live" element={<Live />} />
        <Route path="/live/rsvp" element={<PhoneForm />} />
      </Routes>
    </Router>
  );
}

export default App;
