import { Link } from "react-router-dom";
import "../App.css";

export default function NotFound() {
  return (
    <div className="App">
      <div className="container">
        <div className="title">404NOTFOUND</div>
        <div className="text">seems like you might be lost...</div>
        <div className="links">
          <Link to="/">back home</Link>
        </div>
      </div>
    </div>
  );
}
