import { useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";

export default function EmailForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false); // ✅ Added to track submission status

  const GOOGLE_FORM_ACTION_URL =
    "https://docs.google.com/forms/d/e/1FAIpQLSe7g5RSw04slyGa4wi-6zNm5kpAEUNVgKZywi8nMTg0sHHFaA/formResponse";
  const GOOGLE_FORM_NAME_ENTRY_ID = "entry.264828208"; // Replace with actual ID
  const GOOGLE_FORM_EMAIL_ENTRY_ID = "entry.1989561496"; // Replace with actual ID

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!name.trim()) {
      setMessage("please enter your name...");
      return;
    }

    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setMessage("please enter a valid email address...");
      return;
    }

    const formData = new FormData();
    formData.append(GOOGLE_FORM_NAME_ENTRY_ID, name);
    formData.append(GOOGLE_FORM_EMAIL_ENTRY_ID, email);

    fetch(GOOGLE_FORM_ACTION_URL, {
      method: "POST",
      mode: "no-cors", // Prevents CORS issues
      body: formData,
    })
      .then(() => {
        setMessage("thank you, see you soon");
        setIsSubmitted(true); // ✅ Hide the form after submission
      })
      .catch((error) => {
        setMessage("error... try again");
        console.error("Submission error:", error);
      });
  };

  return (
    <div className="App">
      <div className="container">
        <div className="title">RSVP</div>

        {/* ✅ Show form only if not submitted */}
        {!isSubmitted ? (
          <form onSubmit={handleSubmit} className="form">
            <input
              type="text"
              placeholder="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit">rsvp</button>
          </form>
        ) : (
          <p className="text">{message}</p> // ✅ Show message when submitted
        )}

        <div className="links">
          <Link to="/">back home</Link>
        </div>
      </div>
    </div>
  );
}
