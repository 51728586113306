import { Link } from "react-router-dom";
import "../App.css";

export default function Live() {
  return (
    <div className="App">
      <div className="container">
        <div className="title">nothing is ever wrong</div>
        <div className="content">
          <div className="text">05.03.25 - 9:00PM</div>
          <Link to="/live/rsvp">RSVP</Link>
        </div>
        <div className="links">
          <Link to="/">home</Link>
        </div>
      </div>
    </div>
  );
}
